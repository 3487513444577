import ApiConstants from "../utils/apiConstants";
import { axiosGet } from "./apiPackage/axiosRequest";

async function getPastGebById({ id }) {

    try {
        var response = await axiosGet({
            endpoint: `${ApiConstants.GET_PAST_GEB_BY_ID}/${id}`,
        })
        const data = response.data;
        // console.log("response = ", data);
        // dispatch(setPastGeb(data));
        return data;
    } catch (error) {
        console.log("error = ", error)
        if (!error?.response) {
            alert("No Server Response.");
        } else if (error.response?.status === 404) {
            alert("Page Not Found");
        } else if (error.response?.status === 400 || error.response?.status === 401) {
            alert("User Credit Expired!!!\nPlease Login Again.");
        } else {
            alert("Internal Server Error.");
        }
        return "";
    }
}

export default getPastGebById;