import { SET_ENTREPRENEUR_TEXT, SET_ENTREPRENEURS } from "./SectionNineAction";

const initialState = {
  entrepreneurText: "",
  entrepreneurs: [],
};

const sectionNineReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENTREPRENEUR_TEXT:
      return {
        ...state,
        entrepreneurText: action.payload,
      };
    case SET_ENTREPRENEURS:
      return {
        ...state,
        entrepreneurs: action.payload,
      };

    default:
      return state;
  }
};

export default sectionNineReducer;
