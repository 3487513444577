import CryptoJS from 'crypto-js';

const SECRET_KEY = 'bodhi@bodhi@bodhi';

function encrypt(value) {
    const ciphertext = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
    return ciphertext;
}

function decrypt(ciphertext) {

    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    // console.log("plaintext = ", plaintext);
    return plaintext;
}

export { encrypt, decrypt };