import ApiConstants from "../../../../../../utils/apiConstants";
import { axiosGet } from "../../../../../../packages/apiPackage/axiosRequest";

async function fetchAllSpeakers({ setSpeaker, editionId }) {

    try {
        var response = await axiosGet({
            endpoint: `${ApiConstants.GET_PE_SPEAKERS}/${editionId}`,
        })
        const data = response.data;
        // console.log("response = ", data);
        if (setSpeaker) {
            setSpeaker(data);
            return;
        } else {
            return data;
        }
    } catch (error) {
        console.log("error = ", error)
        if (!error?.response) {
            alert("No Server Response.");
        } else if (error.response?.status === 404) {
            alert("Page Not Found");
        } else if (error.response?.status === 400 || error.response?.status === 401) {
            alert("User Credit Expired!!!\nPlease Login Again.");
        } else {
            alert("Internal Server Error.");
        }
        return;
    }
}

export default fetchAllSpeakers;