import { SET_HIGHLIGHT } from "./SectionElevenAction";

const initialState = {
  highlight: [],
};

const sectionElevenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HIGHLIGHT:
      return {
        ...state,
        highlight: action.payload,
      };

    default:
      return state;
  }
};

export default sectionElevenReducer;
