import { SET_TESTIMONIAL } from "./SectionTwelveAction";

const initialState = {
  testimonial: [],
};

const sectionTwelveReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TESTIMONIAL:
      return {
        ...state,
        testimonial: action.payload,
      };

    default:
      return state;
  }
};

export default sectionTwelveReducer;
