import { useEffect } from "react";
import { useNavigate } from "react-router";
import { GetUser } from "../../packages/cookiesPackage/ManageUserState";

const ProtectedPage = ({ pageToBeProtected, isLoginPage = false }) => {
    let navigate = useNavigate();
    useEffect(() => {
        const user = GetUser();
        // console.log("user pp = ", user);
        // console.log("is pp= ", isLoginPage);
        if (!user && !isLoginPage) {
            navigate("/admin");
        } else if (user && isLoginPage) {
            navigate("/admin/home");
        }

    });

    return (
        <>
            {pageToBeProtected}
        </>
    );
};


export default ProtectedPage;
