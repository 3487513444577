import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HandleLoginSubmit from "./packages/handleLoginSubmit";
import "./adminLogin.css"

const AdminLogin = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState("admin")
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleText = (e) => { setEmail(e.target.value); }
  const handlePass = (e) => { setPassword(e.target.value); }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let success = await HandleLoginSubmit({
      btnLoading: btnLoading, setBtnLoading: setBtnLoading,
      email: email, password: password, role: role
    })
    if (success) {
      window.location.reload(false);
      // navigate('home');
    }
  }

  return (
    <div className="alogin_container">
      <h1 className="alogin_header">Login</h1>
      <form className="alogin_form_container"
        onSubmit={handleSubmit}>
        <select value={role} onChange={(e) => {
          setRole(e.target.value)
        }}>
          <option value="admin">Admin</option>
          <option value="editor">Editor</option>
        </select>
        <input id="email" value={email} type="email"
          onChange={handleText} className="alogin_email"
          placeholder="Username/Email" required />
        <input id="password" value={password}
          type={showPassword ? "text" : "password"}
          onChange={handlePass} className="alogin_password"
          placeholder="Passcode" required />
        <div className='alogin_show_password_div'>
          <input className='alogin_show_password_checkbox'
            type="checkbox"
            onClick={(e) => { setShowPassword(!showPassword); }} />
          Show Password
        </div>
        <input type="submit" className="alogin_button primary_btn"
          value={btnLoading ? "Loading..." : "login"} />
      </form>
    </div>
  );
}
export default AdminLogin;
