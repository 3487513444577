import { SET_PARTNER } from "./SectionSevenAction";

const initialState = {
  partner: [],
};

const sectionSevenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTNER:
      return {
        ...state,
        partner: action.payload,
      };

    default:
      return state;
  }
};

export default sectionSevenReducer;
