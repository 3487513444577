import { SET_IMPACT } from "./SectionOneAction";

const initialState = {
  impact: [],
};

const sectionOneReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IMPACT:
      return {
        ...state,
        impact: action.payload,
      };

    default:
      return state;
  }
};

export default sectionOneReducer;
