import {
  SET_EARLY_FEE,
  SET_EARLY_SCHOLARSHIP,
  SET_EARLY_DEADLINE,
  SET_REGULAR_FEE,
  SET_REGULAR_DEADLINE,
  SET_REGULAR_SCHOLARSHIP,
  SET_LATE_FEE,
  SET_LATE_DEADLINE,
  SET_LATE_SCHOLARSHIP,
} from "./sectionFourAction";

const initialState = {
  earlyFee: "",
  earlyDeadline: "",
  earlyScholarship: "",
  regularFee: "",
  regularDeadline: "",
  regularScholarship: "",
  lateFee: "",
  lateDeadline: "",
  lateScholarship: "",
};

const sectionFourReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EARLY_FEE:
      return {
        ...state,
        earlyFee: action.payload,
      };
    case SET_EARLY_DEADLINE:
      return {
        ...state,
        earlyDeadline: action.payload,
      };
    case SET_EARLY_SCHOLARSHIP:
      return {
        ...state,
        earlyScholarship: action.payload,
      };
    case SET_REGULAR_FEE:
      return {
        ...state,
        regularFee: action.payload,
      };
    case SET_REGULAR_DEADLINE:
      return {
        ...state,
        regularDeadline: action.payload,
      };
    case SET_REGULAR_SCHOLARSHIP:
      return {
        ...state,
        regularScholarship: action.payload,
      };
    case SET_LATE_FEE:
      return {
        ...state,
        lateFee: action.payload,
      };
    case SET_LATE_DEADLINE:
      return {
        ...state,
        lateDeadline: action.payload,
      };
    case SET_LATE_SCHOLARSHIP:
      return {
        ...state,
        lateScholarship: action.payload,
      };
    default:
      return state;
  }
};

export default sectionFourReducer;
