import { configureStore } from "@reduxjs/toolkit";
import homeBannerReducer from "./HomePage/HomeBanner/homeBannerReducer";
import organizersReducer from "./OrganizerPage/organizersReducer";
import GebEditionReducer from "./GebEdition/gebEdtionReducer";
import sectionFourReducer from "./HomePage/SectionFour/sectionFourReducer";
import sectionNineReducer from "./HomePage/SectionNine/SectionNineReducer";
import sectionTwelveReducer from "./HomePage/SectionTwelve/SectionTwelveReducer";
import sectionTwoReducer from "./HomePage/SectionTwo/SectionTwoReducer";
import sectionElevenReducer from "./HomePage/SectionEleven/SectionElevenReducer";
import sectionOneReducer from "./HomePage/SectionOne/SectionOneReducer";
import sectionThirteenReducer from "./HomePage/SectionThirteen/SectionThirteenReducer";
import sectionThirteenTwoReducer from "./HomePage/SectionThirteenTwo/SectionThirteenTwoReducer";
import sectionThreeReducer from "./HomePage/SectionThree/SectionThreeReducer";
import sectionTenReducer from "./HomePage/SectionTen/SectionTenReducer";

import sectionTwelveTwoReducer from "./HomePage/SectionTwelveTwo/SectionTwelveTwoReducer";
import sectionSevenReducer from "./HomePage/SectionSeven/SectionSevenReducer";
import FAQReducers from './FaqsPage/FaqsReducer'
const store = configureStore({
  reducer: {
    homeBanner: homeBannerReducer,
    organizers: organizersReducer,
    gebEdition: GebEditionReducer,
    sectionFour: sectionFourReducer,
    sectionNine: sectionNineReducer,
    sectionTwelve: sectionTwelveReducer,
    sectionTwelveTwo: sectionTwelveTwoReducer,
    sectionTwo: sectionTwoReducer,
    sectionEleven: sectionElevenReducer,
    sectionOne: sectionOneReducer,
    sectionThirteen: sectionThirteenReducer,
    sectionThirteenTwo: sectionThirteenTwoReducer,
    sectionThree: sectionThreeReducer,
    sectionTen: sectionTenReducer,
    sectionSeven: sectionSevenReducer,
    FAQS: FAQReducers, 
  },
});

export default store;
