import { useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';

const DesktopNavbar = ({ Logo, currentGeb, handleNavigation, selectedItem }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const params = useParams();
  const location = useLocation();

  // const handleDropdownToggle = () => {
  //     setDropdownOpen(!dropdownOpen);
  // };


  const handleDropdownMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleDropdownMouseLeave = () => {
    setDropdownOpen(false);
  };


  const pastGebs = useSelector((state) => state.gebEdition.pastGebs);
  function refreshPage() {
    window.location.reload(false);
  }

  const pastEditionSelectionHandle = ({ id }) => {
    handleNavigation({ goTo: 2, id: id });
  }

  const navbarInnerWrapperRef = useRef(null);
  useEffect(() => {
    const navbarInnerWrapperHeight = navbarInnerWrapperRef.current.clientHeight;
    const dropdownMenu = document.querySelector(".dropdown_menu");
    if (dropdownMenu) {
      // console.log("niwh = ", navbarInnerWrapperHeight)
      dropdownMenu.style.marginTop = `${80}px`;
    }
  }, []);

  const isCurrentRoute = (pathname, allowPartialMatch = false) => {
    if (allowPartialMatch) {
      return location.pathname.includes(pathname);
    }

    return location.pathname === pathname;
  };

  return (
    <div className="navbar_inner_wrapper" ref={navbarInnerWrapperRef}>
      <div className="navbar_logo">
        <Logo width={90} marginY={1} />
      </div>
      <nav className="navbar_contents">
        <ul className="navbar_links">
          <li className={`navbar_item ${isCurrentRoute("/") ? "active" : ""}`}>
            <Link preventScrollReset={false} to="/">Home</Link>
          </li>
          <li className={`navbar_item ${isCurrentRoute("/aboutGeb") ? "active" : ""}`}>
            <Link preventScrollReset={false} to="/aboutGeb">About</Link>
          </li>
          <li className={`navbar_dropdown ${isCurrentRoute("/pastGeb", true) ? "active" : ""}`}
            onMouseEnter={handleDropdownMouseEnter}
            onMouseLeave={handleDropdownMouseLeave}>
            <div className="navbar_item" >Past GEBs <ExpandMoreIcon /></div>
            {pastGebs ? <ul className={`dropdown_menu ${dropdownOpen ? "show" : ""}`}>
              {
                pastGebs.map((pastGeb, index) => {
                  var nth = `${pastGeb.editionNumber}th`;
                  if (pastGeb.editionNumber === 1) {
                    nth = `${pastGeb.editionNumber}st`
                  } else if (pastGeb.editionNumber === 2) {
                    nth = `${pastGeb.editionNumber}nd`
                  } else if (pastGeb.editionNumber === 3) {
                    nth = `${pastGeb.editionNumber}rd`
                  }

                  return (
                    <li key={index}
                      className={`dropdown_item ${pastGeb._id === params.id ? "active" : ""}`}>

                      <Link preventScrollReset={false} to={`/pastGeb/${pastGeb._id}`}>
                        {`GEB ${pastGeb.eventLocation ?? "Dubai"}
                                             (${nth} Edition)`}
                      </Link>
                    </li>
                  );
                })
              }
            </ul> : ""}
          </li>
          <li className={`navbar_item ${isCurrentRoute("/upcommingGeb") ? "active" : ""}`}>
            <Link preventScrollReset={false} to="/upcommingGeb">
              {currentGeb.editionNumber}th GEB  {currentGeb.eventLocation}
            </Link>
          </li>
          <li className={`navbar_item ${isCurrentRoute("/silicon-valley-exchange-program") ? "active" : ""}`}>
            <Link preventScrollReset={false} to="/silicon-valley-exchange-program">SVEP</Link>
          </li>
          <li className={`navbar_item ${isCurrentRoute("/geb-venture-fund") ? "active" : ""}`}>
            <Link preventScrollReset={false} to="/geb-venture-fund">GEB Venture Fund</Link>
          </li>
          <li className={`navbar_item ${isCurrentRoute("/contacts") ? "active" : ""}`}>
            <Link preventScrollReset={false} to="/contacts">Contact</Link>
          </li>
          <div className="navbar_btn_container">
            <Link preventScrollReset={false} to="/applyNow" className="navbar_btn">Apply Now</Link>
          </div>
        </ul>
      </nav>
    </div >
  );
};

export default DesktopNavbar;
