import { SET_VIDEO } from "./SectionTwelveTwoAction";

const initialState = {
  video: [],
};

const sectionTwelveTwoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO:
      return {
        ...state,
        video: action.payload,
      };

    default:
      return state;
  }
};

export default sectionTwelveTwoReducer;
