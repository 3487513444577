import { useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Popup from "reactjs-popup";
import { useSelector } from 'react-redux';
import "./drawer.css"

const Drawer = ({ Logo, currentGeb, handleNavigation, selectedItem }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const pastGebs = useSelector((state) => state.gebEdition.pastGebs);
  const params = useParams();
  const location = useLocation();

  function refreshPage() {
    window.location.reload(false);
  }

  const isCurrentRoute = (pathname, allowPartialMatch = false) => {
    if (allowPartialMatch) {
      return location.pathname.includes(pathname);
    }

    return location.pathname === pathname;
  };


  const pastEditionSelectionHandle = ({ id, close }) => {
    handleNavigation({ goTo: 2, id: id });
    close();
  }

  return (
    <div className="drawer_container">
      <div className="drawer_navbar_logo">
        <Logo width={70} marginY={1} />
      </div>
      <Popup
        trigger={
          <div className="drawer_menu_icon_container" >
            <MenuIcon className='drawer_menu_icon' />
          </div>
        }
        modal
      >
        {(close) => {
          return (
            <div className="open_drawer_container">
              <div style={{ maxHeight: "100vh", overflowY: "auto" }}
                className="open_drawer_inner_container">
                <div className="drawer_logo_container">
                  <Logo width={80} marginY={1} />
                </div>
                <div className="drawer_horizontal_line"></div>
                <nav className="drawer_contents">
                  <ul className="drawer_links">
                    <li className={`drawer_item ${isCurrentRoute("/") ? "active" : ""}`}>
                      <Link preventScrollReset={false} to="/" onClick={() => close()}>Home</Link>
                    </li>
                    <div className="drawer_horizontal_line"></div>
                    <li className={`drawer_item ${isCurrentRoute("/aboutGeb") ? "active" : ""}`}>
                      <Link preventScrollReset={false} to="/aboutGeb" onClick={() => close()}>About</Link>
                    </li>
                    <div className="drawer_horizontal_line"></div>
                    <li className={`drawer_item ${isCurrentRoute("/pastGeb", true) ? "active" : ""}`} onClick={() => {
                      setDropdownOpen(!dropdownOpen);
                    }}>
                      <span>Past GEBs</span> {
                        dropdownOpen ?
                          <ExpandLessIcon className='d_expand_icon' />
                          : <ExpandMoreIcon className='d_expand_icon' />
                      }
                    </li>
                    {pastGebs ?
                      <li className={`drawer_dropdown_menu ${dropdownOpen ? "" : "hide_dropdown"}`}>
                        {
                          pastGebs.map((pastGeb, index) => {
                            var nth = `${pastGeb.editionNumber}th`;
                            if (pastGeb.editionNumber === 1) {
                              nth = `${pastGeb.editionNumber}st`
                            } else if (pastGeb.editionNumber === 2) {
                              nth = `${pastGeb.editionNumber}nd`
                            } else if (pastGeb.editionNumber === 3) {
                              nth = `${pastGeb.editionNumber}rd`
                            }

                            return (
                              <div key={index}>
                                <li className={`drawer_dropdown_item ${pastGeb._id === params.id ? "active" : ""}`}>
                                  <Link preventScrollReset={false} to={`/pastGeb/${pastGeb._id}`} onClick={() => close()}>
                                    {`GEB ${pastGeb.eventLocation ?? "Dubai"}(${nth} Edition)`}
                                  </Link>
                                </li>
                                <div className="drawer_horizontal_line"></div>
                              </div>
                            );
                          })
                        }
                      </li> : ""}
                    <div className="drawer_horizontal_line"></div>
                    <li className={`drawer_item ${isCurrentRoute("/upcommingGeb") ? "active" : ""}`}>
                      <Link preventScrollReset={false} to="/upcommingGeb" onClick={() => close()}>{currentGeb.editionNumber}th GEB  {currentGeb.eventLocation}</Link>
                    </li>
                    <div className="drawer_horizontal_line"></div>
                    <li className={`drawer_item ${isCurrentRoute("/silicon-valley-exchange-program") ? "active" : ""}`}>
                      <Link preventScrollReset={false} to="/silicon-valley-exchange-program">SVEP</Link>
                    </li>
                    <div className="drawer_horizontal_line"></div>
                    <li className={`drawer_item ${isCurrentRoute("/geb-venture-fund") ? "active" : ""}`}>
                      <Link preventScrollReset={false} to="/geb-venture-fund">GEB Venture Fund</Link>
                    </li>
                    <div className="drawer_horizontal_line"></div>
                    <li className={`drawer_item ${isCurrentRoute("/faqs") ? "active" : ""}`}>
                      <Link preventScrollReset={false} to="/faqs" onClick={() => close()}>FAQ</Link>
                    </li>
                    <div className="drawer_horizontal_line"></div>
                    <li className={`drawer_item ${isCurrentRoute("/contacts") ? "active" : ""}`}>
                      <Link preventScrollReset={false} to="/contacts" onClick={() => close()}>Contact</Link>
                    </li>
                    <div className="drawer_horizontal_line"></div>
                    <li className={`drawer_item ${isCurrentRoute("/applyNow") ? "active" : ""}`}>
                      <Link preventScrollReset={false} to="/applyNow" onClick={() => close()}>Apply Now</Link>
                    </li>
                  </ul>
                  <div className="drawer_horizontal_line"></div>
                </nav>
              </div>
              <div className="drawer_remaining_container"
                onClick={() => { close() }} >
                <div className="drawer_close_icon_container" >
                  <CloseIcon className='drawer_close_icon' />
                </div>
              </div>
            </div >
          );
        }}
      </Popup >
    </div >
  );
};

export default Drawer;
