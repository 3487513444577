import React from 'react';
import lottie from 'lottie-web';
import lottieFile from "../assets/loading.json";

const LottieAnimation = ({
    width, height
}) => {
    const lottieContainerRef = React.useRef(null);
    React.useEffect(() => {
        lottie.loadAnimation({
            container: lottieContainerRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: lottieFile,
        });
    }, []);

    return (
        <div style={{ width: "100vw", display: "flex", justifyContent: "center"}} >
            <div
                ref={lottieContainerRef}
                style={{
                    width: width ?? '200px',
                    height: height ?? '200px'
                }}
            />
        </div>
    );
};

export default LottieAnimation;