import "./Navbar.css";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../Logo";
import fetchUpcommingGeb from "../../packages/fetchUpcommingGeb";
import { setUpcommingGeb, setPastGeb } from "../../state/GebEdition/gebEditionActions"
import DesktopNavbar from "./components/desktopNavbar/desktopNavbar";
import Drawer from "./components/drawer/drawer";
import fetchPastGeb from "../../packages/fetchPastGeb";

const Navbar = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(0);
  const currentGeb = useSelector((state) => state.gebEdition.currentGeb);

  useEffect(() => {
    fetchUpcommingGeb({ setUpcommingGeb, dispatch })
  }, []);

  useEffect(() => {
    fetchPastGeb({ setPastGeb, dispatch })
  }, []);

  const handleNavigation = ({ goTo, id }) => {
    setSelectedItem(goTo);
    switch (goTo) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/aboutGeb");
        break;
      case 2:
        if (id)
          navigate(`/pastGeb/${id}`);
        break;
      case 3:
        navigate("/upcommingGeb");
        break;
      case 4:
        navigate("/organizers");
        break;
      case 5:
        navigate("/faqs");
        break;
      case 6:
        navigate("/contacts");
        break;
      case 100:
        navigate("/applyNow");
        break;
      default:
        break;
    }
  };

  // console.log("cu geb = ", currentGeb.editionNumber)

  return (
    <div className="navbar_container sticky">
      <Drawer handleNavigation={handleNavigation} selectedItem={selectedItem}
        currentGeb={currentGeb} Logo={Logo} />
      <DesktopNavbar handleNavigation={handleNavigation} selectedItem={selectedItem}
        currentGeb={currentGeb} Logo={Logo} />
    </div>
  );
};

export default Navbar;
