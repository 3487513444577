import { Typography, Link } from "@mui/material";

const APP_NAME = "GEB";

const AppNameWithLink = () => {
    return (
        <Link color="inherit" underline="none" href="/">
            {APP_NAME}
        </Link>
    );
};

function CopyRight(props) {
    return (
        <Typography color="gray" align="center" {...props} fontSize={14}
            marginBottom={2.5} marginTop={1.5}>
            {'Copyright © '}
            <AppNameWithLink />
            {` ${new Date().getFullYear()} | Designed by `}
            <AppNameWithLink />
            {' - Powered by '}
            <AppNameWithLink />
        </Typography>
    );
}

export default CopyRight;
