import Cookies from 'universal-cookie';
import { encrypt, decrypt } from '../Encryption';

const cookies = new Cookies();

function SetTokensCookies({ accessToken, refreshToken }) {
    // const twentyMinutesInMilliseconds = 20 * 60 * 1000; // 20 minutes
    const threeHoursInMilliseconds = 3 * 60 * 60 * 1000; // 3 hours

    const accessTokenOptions = {
        path: '/',
        expires: new Date(Date.now() + threeHoursInMilliseconds),
    };
    cookies.set('1', encrypt(accessToken), accessTokenOptions);

    const refreshTokenOptions = {
        path: '/',
        expires: new Date(Date.now() + threeHoursInMilliseconds),
    };
    cookies.set('2', encrypt(refreshToken), refreshTokenOptions);
}

function GetAccessToken() {
    // console.log("got here gat = ", cookies.get('1'))
    const accessToken = decrypt(cookies.get('1'));
    // console.log("at", accessToken)
    return accessToken;
}

function GetRefreshToken() {
    const refreshToken = decrypt(cookies.get('2'));
    return refreshToken;
}

export { SetTokensCookies, GetAccessToken, GetRefreshToken };
