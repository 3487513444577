export const SET_UPCOMMING_GEB = "SET_UPCOMMING_GEB";
export const SET_PAST_GEB = "SET_PAST_GEB";

export const setUpcommingGeb = (currentGeb) => ({
    type: SET_UPCOMMING_GEB,
    payload: currentGeb,
});

export const setPastGeb = (currentGeb) => ({
    type: SET_PAST_GEB,
    payload: currentGeb,
});


