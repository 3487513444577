import { axiosLoginPost } from "../../../../packages/apiPackage/axiosRequest";
import { SetTokensCookies } from "../../../../packages/cookiesPackage/ManageTokensWithCookies";
import ApiConstants from "../../../../utils/apiConstants";
import { SetUserCookie } from "../../../../packages/cookiesPackage/ManageUserState";
import clearAllCookies from "../../../../packages/cookiesPackage/ClearAllCookies";

async function HandleLoginSubmit({ btnLoading, setBtnLoading, email, password, role  }) {

    if (!btnLoading) {
        setBtnLoading(true);
        // console.log("email = ", email, "password = ", password);
        try {
            var response;
            if (role === "admin") {
                response = await axiosLoginPost({
                    endpoint: ApiConstants.ADMIN_LOGIN_URL,
                    jsonBody: JSON.stringify({ email: email, password: password })
                })
            } else {
                console.log("here")
                response = await axiosLoginPost({
                    endpoint: ApiConstants.ADMIN_LOGIN_EDITOR,
                    jsonBody: JSON.stringify({
                        email: email,
                        password: password
                    })
                })
            }
            SetTokensCookies({
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken
            })
            console.log("response = ", response);

            const user = {
                id: response.data.admin._id,
                name: response.data.admin.name, email: response.data.admin.email, role
            }

            SetUserCookie({ user: user })
            setBtnLoading(false);
            return true;
        } catch (error) {
            console.log("error = ", error)
            setBtnLoading(false);
            clearAllCookies();
            if (!error?.response) {
                alert("No Server Response.");
            } else if (error.response?.status === 404 || error.response?.status === 401) {
                alert("Please Enter valid Email/Passcode !!!");
            } else {
                alert("Login Failed.");
            }
            return false;
        }
    }
}

export default HandleLoginSubmit;