export const SET_EXECUTIVE_TEAM = "SET_EXECUTIVE_TEAM";
export const SET_COUNTRY_DIRECTORS = "SET_COUNTRY_DIRECTORS";

export const setExecutiveTeam = (executiveTeam) => ({
    type: SET_EXECUTIVE_TEAM,
    payload: executiveTeam,
});

export const setCountryDirectors = (countryDirectors) => ({
    type: SET_COUNTRY_DIRECTORS,
    payload: countryDirectors,
});

