import { useNavigate } from "react-router";

let ErrorElement = () => {
    let navigate = useNavigate();

    setTimeout(() => {
        navigate("/");
    }, 2000);
    return (
        <div>
        </div>
    );
};

export default ErrorElement;