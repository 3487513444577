export const SET_BOOTCAMP= "SET_BOOTCAMP";
export const SET_APPLICATION = "SET_APPLICATION";
export const SET_REGISTRATION = "SET_REGISTRATION";

export const setBootcamp = (bootcamp) => ({
    type: SET_BOOTCAMP,
    payload: bootcamp,
});

export const setApplication= (application) => ({
    type: SET_APPLICATION,
    payload: application,
});
export const setRegistration = (registration)=>({
    type: SET_REGISTRATION,
    payload: registration,
})