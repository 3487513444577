class ApiConstants {
  static BACKEND_BASE_URL = "https://backend.gebootcamp.com/api";
  // static BACKEND_BASE_URL = "http://localhost:8080/api";

  static ADMIN_LOGIN_URL = "/admin/loginAdmin";
  static ADMIN_GET_ALL = "/admin/getAllAdmin";
  static ADMIN_ADD_ADMIN = "/admin/registerAdmin"
  static ADMIN_ADD_EDITOR = "/admin/addEditor"
  static ADMIN_DELETE_ADMIN = "/admin/deleteAdmin"
  static ADMIN_DELETE_EDITOR = "/admin/deleteEditor"
  static ADMIN_CHANGE_PASSWORD = "/admin/changePassword"
  static ADMIN_LOGIN_EDITOR = "/admin/loginEditor"

  //home banner
  static UPDATE_HOMEBANNER = "/homePage/updateHomeBanner";
  static UPLOAD_EDITION_TIMELINE = "/editionTimeline/uploadPicture";
  static UPDATE_APPLYCARD = "/homePage/updateApplyCard";
  static UPDATE_IMPACTCARD = "/homePage/updateImpactCard";
  static UPDATE_AWARDCARD = "/homePage/updateAwardCard";
  static UPDATE_COLLABORATOR = "/homePage/updateCollaboratorCard";
  static UPDATE_ENTREPRENEUR_TEXT = "/homePage/updateEnterprenuerCard";
  static UPDATE_SPEAKER_TEXT = "/homePage/updateSpeakerCard";
  static ADD_PARTNER = "/homePage/createCompany";
  static ADD_HIGHLIGHT = "/homePage/createHighlight";
  static ADD_TESTIMONIAL = "/homePage/createExperience";
  static ADD_MEDIA = "/homePage/createMedia";
  static ADD_ENTREPRENEUR = "/homePage/createEnterprenuer";
  static GET_HOMEBANNER = "/homePage/getHomeBanner";
  static UPDATE_FILES = "/homePage/uploadFile";

  //faqs
  static GET_FAQS = "/faq";
  static UPDATE_DELETE_FAQS = "/faq/id_yeta";
  //contacts
  static SEND_CONTACTS = "/contact/sendEmail";

  //home page
  static GET_APPLYCARD = "/homePage/getApplyCard";
  static GET_ENTREPRENEURCARD = "/homePage/getEnterprenuerCard";
  static GET_TESTIMONIAL = "/homePage/getExperience";
  static GET_AWARD = "/homePage/getAwardCard";
  static GET_HIGHLIGHT = "/homePage/getHighlight";
  static GET_IMPACT = "/homePage/getImpactCard";

  static CREATE_HOME_ORGANIZER_CARD = "/homePage/createOrganizer";
  static GET_ORGANIZER = "/homePage/getOrganizer";
  static DELETE_HOME_ORGANIZER = "/homePage/deleteOrganizer";

  static GET_MEDIA = "/homePage/getMedia";
  static GET_COLLABORATOR = "/homePage/getCollaboratorCard";
  static GET_SPEAKER = "/homePage/getSpeakerCard";
  static GET_VIDEO = "/homePage/getVideo";
  static GET_PARTNER = "/homePage/getPartner";

  static GET_WHY_JOIN = "/homePage/getWhyJoin";
  static UPDATE_WHY_JOIN = "/homePage/updateWhyJoin";

  static UPDATE_FILES = "/homePage/uploadFile";

  //organizer page
  static CREATE_ORGANIZER = "/organizers/createOrganizerMember";
  static GET_EXECUTIVE_TEAM = "/organizers/getExecutiveTeam";
  static GET_EXECUTIVE_TEAM = "/organizers/getExecutiveTeam";
  static GET_COUNTRY_DIRECTORS = "/organizers/getCountryDirectors";
  static UPDATE_EXECUTIVE_TEAM_MEM = "/organizers/updateExecutiveTeamMember";
  static UPDATE_COUNTRY_DIRECTOR_MEM =
    "/organizers/updateCountryDirectorsMember";
  static DELETE_ORGANIZER_MEM = "/organizers/deleteOrganizerMember";
  static UPLOAD_ORGANIZER_PIC = "/organizers/uploadProfilePic";

  //Upcomming geb (note for backend; current geb === upcomming geb)
  static GET_UPCOMMING_GEB = "/currentGeb/getCurrentEdition";
  static UPDATE_UPCOMMING_GEB = "/currentGeb/updateCurrentEdition";
  static GET_UPCOMMING_GEB_INTRODUCTION = "/currentGeb/getIntroduction";
  static GET_UPCOMMING_GEB_PRICE = "/currentGeb/getCEPrice";
  static GET_UPCOMMING_GEB_FEATURES = "/currentGeb/getCurrentEditionFeatures";
  static UPDATE_UPCOMMING_GEB_INTRODUCTION = "/currentGeb/updateIntroduction";
  static GET_UPCOMMING_GEB_PRIZE_HEADING = "/currentGeb/getCEPrizeHeading";
  static UPDATE_UPCOMMING_GEB_PRIZE_HEADING = "/currentGeb/updateCEPrizeHeading";
  static UPDATE_UPCOMMING_GEB_PRICE = "/currentGeb/updateCEPrice";
  static UPDATE_UPCOMMING_GEB_FEATURES =
    "/currentGeb/updateCurrentEditionFeatures";
  static UPLOAD_IMG_FOR_UPCOMMING_GEB = "/currentGeb/uploadPictures";

  //Past geb
  static GET_ALL_PAST_EDITIONS_SPEAKERS = "/pastGeb/getAllEdtionsSpeakers";
  static CREATE_PAST_EDITION = "/pastGeb/createPastEdition";
  static CREATE_PE_SPEAKER = "/pastGeb/createSpeaker";
  static CREATE_PE_BUSINESS_IDEA = "/pastGeb/createBusinessIdea";
  static CREATE_PE_PAST_EXP = "/pastGeb/createPastExperience";
  static GET_ALL_PAST_GEB = "/pastGeb/getAllPastEditions";
  static GET_PAST_GEB_BY_ID = "/pastGeb/getPastEditionById";
  static GET_PE_INTRODUCTION = "/pastGeb/getIntroduction";
  static GET_PE_SHORT_INTRO = "/pastGeb/getShortIntro";
  static GET_PE_SPEAKERS = "/pastGeb/getSpeakers";
  static GET_PE_BUSINESS_IDEAS = "/pastGeb/getBusinessIdeas";
  static GET_PE_EXPERIENCE = "/pastGeb/getPastEditionExperience";
  static UPDATE_PAST_EDITION = "/pastGeb/updatePastEdition";
  static UPDATE_PE_INTRODUCTION = "/pastGeb/updateIntroduction";
  static UPDATE_PE_SHORT_INTRO = "/pastGeb/updateShortIntroduction";
  static UPDATE_PE_SPEAKER = "/pastGeb/updateSpeaker";
  static UPDATE_PE_BUSINESS_IDEA = "/pastGeb/updateBusinessIdea";
  static UPDATE_PE_EXPERIENCE = "/pastGeb/updateExperience";
  static DELETE_PAST_EDITION = "/pastGeb/deletePastEdition";
  static DELETE_PE_SPEAKER = "/pastGeb/deleteSpeaker";
  static DELETE_PE_BUSINESS_IDEA = "/pastGeb/deleteBusinessIdea";
  static DELETE_PE_EXPERIENCE = "/pastGeb/deleteExperience";
  static UPLOAD_PE_PIC = "/pastGeb/uploadPictures";

  //gallary page...
  static UPLOAD_GALLARY_PIC = "/gallary/uploadPictures";
  static CREATE_PIC_REF = "/gallary/createPicRef";
  static GET_PIC_REF = "/gallary/getPicRef";
  static UPDATE_PIC_REF = "/gallary/updatePicRef";
  static DELETE_PIC_REF = "/gallary/deletePicRef";

  //apply now ...
  static GET_GOOGLE_FORM_LINK = "/applyNow/getFormLink";
  static UPDATE_GOOGLE_FORM_LINK = "/applyNow/updateFormLink";

  //partner form ...
  static GET_PARTNER_PAGE = "/partnerForm/getPartnerPage";
  static UPDATE_PARTNER_PAGE = "/partnerForm/updatePartnerPage";

  //about us api router
  static UPDATE_ABOUT_US_TITLE = "/aboutUs/updateAboutUsTitle";
  static UPDATE_ABOUT_US_FIRST_SECTION = "/aboutUs/updateAboutUsFirstSection";
  static ADD_ABOUT_US_FIRST_SECTION_IMAGES = "/aboutUs/addAboutUsFirstSectionImages";
  static DELETE_ABOUT_US_FIRST_SECTION_IMAGES = "/aboutUs/deleteAboutUsFirstSectionImages";
  static UPDATE_ABOUT_US_GEBX = "/aboutUs/updateAboutUsGebX";
  static ADD_ABOUT_US_GEBX_IMAGES = "/aboutUs/addAboutUsGebXImages";
  static DELETE_ABOUT_US_GEBX_IMAGES = "/aboutUs/deleteAboutUsGebXImages";
  static UPDATE_ABOUT_US_WHY_GEB = "/aboutUs/updateAboutUsWhyGeb";
  static ADD_ABOUT_US_WHY_GEB_IMAGES = "/aboutUs/addAboutUsWhyGebImages";
  static DELETE_ABOUT_US_WHY_GEB_IMAGES = "/aboutUs/deleteAboutUsWhyGebImages";
  static UPDATE_AWARD_FIELDS = "/aboutUs/updateAwardFields";
  static ADD_AWARD = "/aboutUs/addAward";
  static DELETE_AWARD = "/aboutUs/deleteAward";
  static UPDATE_AWARD_IMAGES = "/aboutUs/updateAwardImages";
  static ADD_ABOUT_US_PLACES = "/aboutUs/addAboutUsPlaces";
  static REMOVE_ABOUT_US_PLACE = "/aboutUs/removeAboutUsPlace";
  static UPDATE_ABOUT_US_PLACE_AT_INDEX = "/aboutUs/updateAboutUsPlaceAtIndex";
  static UPDATE_ABOUT_US_PLACE_IMAGE = "/aboutUs/updateAboutUsPlaceImage";
  static UPDATE_ABOUT_US_PLACE_SUBTITLE_AT_INDEX = "/aboutUs/updateAboutUsPlaceSubtitleAtIndex";
  static UPDATE_ABOUT_US_PLACE_SUBTITLE_IMAGE_AT_INDEX = "/aboutUs/updateAboutUsSubtitleImageAtIndex";
  static GET_ABOUT_US = "/aboutUs/aboutUsData";

  //blog page
  static GET_ALL_BLOGS = "/blog/getAllBlogs";
  static GET_A_BLOGS = "/blog/getBlog";
  static CREATE_BLOG = "/blog/createBlog";
  static CREATE_BLOG_CONTENTS = "/blog/createBlogContent";
  static UPDATE_BLOG = "/blog/updateBlog";
  static UPDATE_BLOG_CONTENTS = "/blog/updateBlogContents";
  static DELETE_BLOG = "/blog/deleteBlog";
  static UPLOAD_BLOG_PICTURES = "/blog/uploadPictures";

  //extra pages
  static GET_EXTRA_PAGE_BY_TITLE = "/extraPage/getPageByTitle";
  static UPDATE_EXTRA_PAGE_BY_TITLE = "/extraPage/updateExtraPage";
  static DELETE_EXTRA_PAGE_BY_TITLE = "/extraPage/deletePageByTitle";

  //advisors page
  static GET_ALL_ADVISORS = "/advisor/getAllAdvisors";
  static GET_ADVISOR_BY_ID = "/advisor/getAdvisorById";
  static CREATE_ADVISOR = "/advisor/createAdvisor";
  static UPDATE_ADVISOR = "/advisor/updateAdvisor";
  static DELETE_ADVISOR = "/advisor/deleteAdvisor";
  static UPLOAD_ADVISOR_PICTURES = "/advisor/uploadPictures";

  //messageing routes
  static WHATSAPP_LINK_GET = "/message/whatsappLink"
  static CHANGE_WHATSAPP_LINK = "/message/changeWhatsApp"

}

export default ApiConstants;
