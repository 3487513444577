export const SET_ENTREPRENEUR_TEXT = "SET_ENTREPRENEUR_TEXT";
export const SET_ENTREPRENEURS = "SET_ENTREPRENEURS";

export const setEntrepreneurText = (entrepreneurText) => ({
  type: SET_ENTREPRENEUR_TEXT,
  payload: entrepreneurText,
});

export const setEntrepreneurs = (entrepreneurs) => ({
  type: SET_ENTREPRENEURS,
  payload: entrepreneurs,
});
