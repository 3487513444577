export const SET_EARLY_FEE = "SET_EARLY_FEE";
export const SET_EARLY_DEADLINE = "SET_EARLY_DEADLINE";
export const SET_EARLY_SCHOLARSHIP = "SET_EARLY_SCHOLARSHIP";
export const SET_REGULAR_FEE = "SET_REGULAR_FEE";
export const SET_REGULAR_DEADLINE = "SET_REGULAR_DEADLINE";
export const SET_REGULAR_SCHOLARSHIP = "SET_REGULAR_SCHOLARSHIP";
export const SET_LATE_FEE = "SET_LATE_FEE";
export const SET_LATE_DEADLINE = "SET_LATE_DEADLINE";
export const SET_LATE_SCHOLARSHIP = "SET_LATE_SCHOLARSHIP";

export const setEarlyFee = (earlyFee) => ({
  type: SET_EARLY_FEE,
  payload: earlyFee,
});

export const setEarlyDeadline = (earlyDeadline) => ({
  type: SET_EARLY_DEADLINE,
  payload: earlyDeadline,
});

export const setEarlyScholarship = (earlyScholarship) => ({
  type: SET_EARLY_SCHOLARSHIP,
  payload: earlyScholarship,
});

export const setRegularFee = (regularFee) => ({
  type: SET_REGULAR_FEE,
  payload: regularFee,
});

export const setRegularDeadline = (regularDeadline) => ({
  type: SET_REGULAR_DEADLINE,
  payload: regularDeadline,
});

export const setRegularScholarship = (regularScholarship) => ({
  type: SET_REGULAR_SCHOLARSHIP,
  payload: regularScholarship,
});

export const setLateFee = (lateFee) => ({
  type: SET_LATE_FEE,
  payload: lateFee,
});

export const setLateDeadline = (lateDeadline) => ({
  type: SET_LATE_DEADLINE,
  payload: lateDeadline,
});

export const setLateScholarship = (lateScholarship) => ({
  type: SET_LATE_SCHOLARSHIP,
  payload: lateScholarship,
});
