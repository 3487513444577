import { Link, Box } from "@mui/material";
import logo from "../assets/logo.png";

export default function Logo({ link, width, marginY, marginX }) {

    return (
        <Link color="inherit" underline="none" href={link ?? "/"}>
            <Box marginY={marginY ?? 0} marginX={marginX ?? 0} >
                <img src={logo}
                    alt="Logo" width={width ?? 100} />
            </Box>
        </Link>
    );
}
