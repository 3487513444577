import { SET_ORGANIZER } from "./SectionThirteenAction";

const initialState = {
  organizer: [],
};

const sectionThirteenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZER:
      return {
        ...state,
        organizer: action.payload,
      };

    default:
      return state;
  }
};

export default sectionThirteenReducer;
