import { SET_BOOTCAMP, SET_APPLICATION, SET_REGISTRATION } from "./FaqsActions";

const initialState = {
    bootcamp: [],
    application: [],
    registration: [],
};

const faqsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BOOTCAMP:
            return {
                ...state,
                bootcamp: action.payload,
            };
        case SET_APPLICATION:
            return {
                ...state,
                application: action.payload,
            };
        case SET_REGISTRATION:
            return{
                ...state,
                registration: action.payload,

            }
        default:
            return state;
    }
};

export default faqsReducer;