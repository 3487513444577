export const SET_EVENT_DATE = "SET_EVENT_DATE";
export const SET_TITLE = "SET_TITLE";
export const SET_SUBTITLE = "SET_SUBTITLE";

export const setEventDate = (eventDate) => ({
    type: SET_EVENT_DATE,
    payload: eventDate,
});

export const setTitle = (title) => ({
    type: SET_TITLE,
    payload: title,
});

export const setSubtitle = (subtitle) => ({
    type: SET_SUBTITLE,
    payload: subtitle,
});
