import { SET_COLLABORATOR } from "./SectionThreeAction";

const initialState = {
  collaborator: [],
};

const sectionThreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLABORATOR:
      return {
        ...state,
        collaborator: action.payload,
      };

    default:
      return state;
  }
};

export default sectionThreeReducer;
