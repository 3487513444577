function clearAllCookies() {
    // Split the cookies by ';' to get individual cookies
    const cookies = document.cookie.split(';');

    // Iterate over the cookies and remove each one
    cookies.forEach(cookie => {
        const cookieName = cookie.split('=')[0].trim();
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
}

export default clearAllCookies;