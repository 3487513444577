import { SET_MEDIA } from "./SectionThirteenTwoAction";

const initialState = {
  organizer: [],
};

const sectionThirteenTwoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MEDIA:
      return {
        ...state,
        media: action.payload,
      };

    default:
      return state;
  }
};

export default sectionThirteenTwoReducer;
