import axios from "./axios";
// import { GetAccessToken, GetRefreshToken } from "../cookiesPackage/ManageTokensWithCookies";

async function axiosLoginPost({ endpoint, jsonBody }) {
  const response = await axios.post(endpoint, jsonBody, {
    headers: { "Content-Type": "application/json" },
  });

  return response;
}

async function axiosPost({ endpoint, jsonBody, formData }) {
  // const accessToken = GetAccessToken();
  // const refreshToken = GetRefreshToken();

  let headers = {
    // 'Authorization': `Bearer ${accessToken} ${refreshToken}`,
  };

  if (jsonBody) {
    headers["Content-Type"] = "application/json";
  } else if (formData) {
    headers["Content-Type"] = "multipart/form-data";
  }

  const response = await axios.post(endpoint, jsonBody || formData, {
    headers: headers,
  });

  return response;
}

async function axiosGet({ endpoint }) {
  // const accessToken = GetAccessToken();
  // const refreshToken = GetRefreshToken();

  const response = await axios.get(endpoint, {
    headers: {
      "Content-Type": "application/json",
      // 'Authorization': `Bearer ${accessToken} ${refreshToken}`,
    },
  });
  return response;
}

async function axiosDelete({ endpoint }) {
  const response = await axios.delete(endpoint, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
}

export { axiosPost, axiosGet, axiosLoginPost, axiosPatch, axiosDelete };

async function axiosPatch({ endpoint, jsonBody, formData }) {
  // const accessToken = GetAccessToken();
  // const refreshToken = GetRefreshToken();

  // console.log("ac= ", accessToken)
  // console.log("rt= ", refreshToken)
  let headers = {};
  if (jsonBody) {
    headers["Content-Type"] = "application/json";
  } else if (formData) {
    headers["Content-Type"] = "multipart/form-data";
  }

  const response = await axios.patch(endpoint, formData || jsonBody, {
    headers: headers,
  });

  return response;
}
