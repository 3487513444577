import { SET_EXECUTIVE_TEAM, SET_COUNTRY_DIRECTORS } from "./organizersActions";

const initialState = {
    executiveTeam: [],
    countryDirectors: [],
};

const organizersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXECUTIVE_TEAM:
            return {
                ...state,
                executiveTeam: action.payload,
            };
        case SET_COUNTRY_DIRECTORS:
            return {
                ...state,
                countryDirectors: action.payload,
            };
        default:
            return state;
    }
};

export default organizersReducer;
