import Cookies from 'universal-cookie';

const cookies = new Cookies();

function SetUserCookie({ user }) {
    // const twentyMinutesInMilliseconds = 20 * 60 * 1000; // 20 minutes
    const threeHoursInMilliseconds = 3 * 60 * 60 * 1000; // 3 hours

    const accessTokenOptions = {
        path: '/',
        expires: new Date(Date.now() + threeHoursInMilliseconds),
    };
    cookies.set('user', user ?? "", accessTokenOptions);
}

function GetUser() {
    return cookies.get('user');
}

export { SetUserCookie, GetUser };
