import {
  SET_EVENT_DATE,
  SET_TITLE,
  SET_SUBTITLE,
} from "./homeBannerActions";

const initialState = {
  eventDate: "",
  title: "",
  subtitle: "",
};

const homeBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_DATE:
      return {
        ...state,
        eventDate: action.payload,
      };
    case SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case SET_SUBTITLE:
      return {
        ...state,
        subtitle: action.payload,
      };
    default:
      return state;
  }
};

export default homeBannerReducer;
