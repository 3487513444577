import { SET_UPCOMMING_GEB, SET_PAST_GEB } from "./gebEditionActions";

const initialState = {
    currentGeb: {},
    pastGebs: []
};

function arrangeObjectsDescending(objects) {
    return objects.sort((a, b) => b.editionNumber - a.editionNumber);
}

const GebEditionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPCOMMING_GEB:
            return {
                ...state,
                currentGeb: action.payload,
            };
        case SET_PAST_GEB:
            {
                const result = arrangeObjectsDescending(action.payload);
                return {
                    ...state,
                    pastGebs: result,
                };
            }
        default:
            return state;
    }
};

export default GebEditionReducer;
